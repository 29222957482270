import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { TitleService } from 'src/app/core/services/title.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { LoaderService } from 'src/app/loader/services/loader.service';
import { currentMonthRange, formatDate } from 'src/app/utils/dates';
import { environment } from 'src/environments/environment';
import { DataTransferModel } from '../../models/dataTransfer.model';
import { ObservalbleDataService } from '../../services/observalble-data.service';
import { TransformService } from '../../services/transform.service';
import {isEmpty,trim} from 'lodash';
import { Store } from '@ngrx/store';
import { FileService } from 'src/app/files/services/file.service';
import { HttpResponse } from '@angular/common/http';
import { NullTemplateVisitor } from '@angular/compiler';

@Component({
  selector: 'app-transform-output-files',
  templateUrl: './transform-output-files.component.html',
  styleUrls: ['./transform-output-files.component.scss']
})
export class TransformOutputFilesComponent implements OnInit {
  activeRoute$:Subscription;
  headerForm:FormGroup;
  dataList:any;
  columns: string[] = [
    "serial.",
    "Application Client",
    "AsOfDate",
    "workflow name",
    "created by",
    "created at",
    "actions",
  ];
  dataTransferModel: DataTransferModel;
  fromDt: any;
  toDt: any;
  rolePermission:any;
  visibleDelete=false;
  dataValue: any;
    
  constructor(private activeRoute:ActivatedRoute,private titleService:TitleService,
    public observalbleDataService: ObservalbleDataService,
    private transformService:TransformService,
    private loader:LoaderService,
    private toast:ToastService,
    private cookieService: CookieService,private storePermission: Store<{ role: any }>,private fileService: FileService) {

    this.headerForm = new FormGroup({
      client: new FormControl(null, [Validators.required]),
      //dateRange: new FormControl(currentMonthRange(), [Validators.required]),
      fromDate: new FormControl(null, [Validators.required]),
      toDate: new FormControl(null, [Validators.required]),
    });
  }
   

  ngOnInit(): void {
    this.buttonShowHidePermission();
  }

  setTitle() {
    this.activeRoute$ = this.activeRoute.data.subscribe(
      (data) => this.titleService.setTitle(data["title"]),
      (err) => console.log("error", err)
    );
  }

  loadDate(){
    let currentDateRange=currentMonthRange();
    //console.log(currentDateRange,'  current date range');
    this.headerForm.controls.fromDate.setValue(currentDateRange[0]);
    this.headerForm.controls.toDate.setValue(currentDateRange[1]);
    this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
  }

  onClientChange(event){
    this.cookieService.set('clientId',this.headerForm.controls.client.value.key);
    this.cookieService.set('clientName',this.headerForm.controls.client.value.value);
    this.getFilesData(event, this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  //  this.maintainClientState(event, this.headerForm.controls.fromDate.value, this.headerForm.controls.toDate.value)
  }

  getFilesData(client,fromDate,toDate) {
   if(!this.headerForm.valid){
     this.toast.showError('Please fill all the fields','error');
   }
   let validate=this.validationOnDate(this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    if(validate===false){
      this.toast.showWarn('error','date range incorrect')    
    }
    const clientData={
      "clientId":client.key,
      "fromDate":formatDate(fromDate),
      "toDate":formatDate(toDate)
    }
    this.transformService.getAllByClientAndDate(clientData).subscribe(
      data => {
        this.dataList = data;
        this.loader.hideLoader();
      },
      err => {
        this.loader.hideLoader();
        this.toast.showError(err.error.message, "error");
      }
    );
  }

  downloadAnyFile(rowData){
    const data=rowData;
    this.loader.showLoader();
    let downloadSuccess:boolean=false;
    var link=document.createElement('a');
    link.href=environment.baseUrl+"/apis/transformFileStore/downloadOutputFile/"+data.id;      
    link.click();
    this.toast.showSuccess('success','Download Begin Shortly'); 
    this.loader.hideLoader();
  }

  deleteFile(rowData){
    this.dataValue=rowData;
    this.visibleDelete=true;
  }

  async cleanData() {
    const data = this.dataValue;
    this.loader.showLoader();

    try {
        const response = await this.fileService.deleteExtractFile(data).toPromise(); 
        console.log("Response:", response); 

        if (response != null) {
            this.toast.showSuccess('Success', 'File deleted successfully');
            this.visibleDelete = false;
            
            window.location.reload();
        } else {
          this.visibleDelete = false;
          this.toast.showError('Error', 'Unexpected response status');
        }
    } catch (err) {
        console.error("Error:", err);
        this.visibleDelete = false;
        this.toast.showError('Error', err.error?.message);
    } finally {
        this.loader.hideLoader();
    }
}





  hideCleanDataPopup(){
    this.dataValue=null;
    this.visibleDelete=false;
  }

  changeFromDate(event){
    this.cookieService.set('fromDate',this.headerForm.controls.fromDate.value);
    this.getFilesData(this.headerForm.controls.client.value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  }
  
  changeToDate(event){
    this.cookieService.set('toDate',this.headerForm.controls.toDate.value);
    this.getFilesData(this.headerForm.controls.client.value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
  }

  getPreviousState() {
    const clientIdC=this.cookieService.get('clientId');
    const clientNameC=this.cookieService.get('clientName');
    console.log(clientIdC);
    console.log(clientNameC);
    const fromDatec=this.cookieService.get('fromDate');
    const toDatec=this.cookieService.get('toDate');
    
    if(clientIdC!==undefined && clientIdC!==null){
      this.headerForm.get("client").patchValue({
               key: clientIdC,
               value: clientNameC,
               });
                   
                     
    }

    if(fromDatec!==undefined && fromDatec!==null && toDatec!==undefined && toDatec!==null && 
      !isEmpty(fromDatec) && !isEmpty(toDatec)){
       // console.log("hello")
        let frm=new Date(fromDatec);
        let toDt=new Date(toDatec);
       this.headerForm.controls.fromDate.setValue(frm)
       this.headerForm.controls.toDate.setValue(toDt)
    }else{
      this.loadDate();
    }

    if(!isEmpty(this.headerForm.get("client").value.key)){
      this.getFilesData(this.headerForm.controls.client.value,this.headerForm.controls.fromDate.value,this.headerForm.controls.toDate.value);
    }
  }
  
  maintainClientState(client, fromDate, toDate){
    this.dataTransferModel = {
      clientFileId: 0,
      clientId: client.key,
      isRedirected: 1,
      applicationId: 0,
      toDate: toDate,
      fromDate: fromDate,
      clientName: client.value,
      page: '',
      fileName: '',
      workflowName: '',
      workflowId: 0,
      dataType: '',
      dataTypeId: 0
    }
    this.observalbleDataService.addToInventory(this.dataTransferModel); 
  }

  validationOnDate(fromdate,todate){
    if(fromdate && todate){
    if(new Date(fromdate)>new Date(todate)){
    
        return false;
      }
      return true;
      
    }else{
      return false;
    }
  }
  buttonShowHidePermission(){
    this.fileService.buttonShowHidePermission().subscribe(
      (res) =>{ 
           this.rolePermission = res  
           this.callNgOnInitMethods();
   }
    )};
  
    callNgOnInitMethods(){
      this.getPreviousState();
      this.setTitle();
    //this.loadDate();
  }
}
